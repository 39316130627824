<template>
    <element-row :label="label">
        <span v-if="$page.props.errors.report" class="error--text">{{ Array.isArray($page.props.errors.report) ? $page.props.errors.report.toString() : $page.props.errors.report }}</span>
        <p v-for="(report, index) in reports" :key="index" class="mb-0">
            <span :class="report.invalided_at ? 'text--disabled' : null">{{ report.name }} {{ report.invalided_at !== null ? ` (${formatDate(report.invalided_at)})` : null}}</span>
            <v-btn color="primary" icon :href="generateReportLink(analysis, report)">
                <v-icon>mdi-download</v-icon>
            </v-btn>
            <v-tooltip bottom v-if="report.invalided_at === null">
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        color="success"
                        v-bind="attrs"
                        v-on="on"
                    >mdi-check-circle
                    </v-icon>
                </template>
                <span>Rapport valide</span>
            </v-tooltip>
        </p>
    </element-row>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import ElementRow from "@/Components/generic/ElementRow.vue";
import {AnalysisReport, CnvAnalysis, NgsAnalysis, NgsPairAnalysis} from "@/models";

@Component({
    components: {ElementRow}
})
export default class Report extends Vue {
    @Prop() readonly analysis!: CnvAnalysis | NgsAnalysis | NgsPairAnalysis
    @Prop() readonly reports!: Array<AnalysisReport>
    @Prop() readonly label!: string

    isCnvAnalysis(analysis: CnvAnalysis | NgsAnalysis | NgsPairAnalysis): analysis is CnvAnalysis {
        return 'name' in analysis
    }
    isNgsAnalysis(analysis: CnvAnalysis | NgsAnalysis | NgsPairAnalysis): analysis is NgsAnalysis {
        return 'ngs_library_id' in analysis
    }
    isNgsPairAnalysis(analysis: CnvAnalysis | NgsAnalysis | NgsPairAnalysis): analysis is NgsPairAnalysis {
        return 'ngs_tumoral_library_id' in analysis
    }

    generateReportLink(analysis: CnvAnalysis | NgsAnalysis | NgsPairAnalysis, report: AnalysisReport): string {
        let routeName: string;
        let params: Record<string, any>;

        if (this.isCnvAnalysis(analysis)) {
            routeName = 'cnv_analyses.download_report';
            params = { cnv_analysis: analysis.id, analysis_report: report.id };
        } else if (this.isNgsAnalysis(analysis)) {
            routeName = 'ngs_analyses.download_report';
            params = { ngs_analysis: analysis.id, analysis_report: report.id };
        } else if (this.isNgsPairAnalysis(analysis)) {
            routeName = 'ngs_pair_analyses.download_report';
            params = { ngs_pair_analysis: analysis.id, analysis_report: report.id };
        }

        return this.$route(routeName, params);
    }
}
</script>

<style scoped lang="scss">
</style>
