<template>
    <v-data-table
        v-model="selectedLibraries"
        :headers="headers"
        :items="filteredItems"
        item-key="id"
        :loading="loading"
        :footer-props="footerProps"
        :server-items-length="serverItemsLength"
        :page="syncedPage"
        :items-per-page="syncedItemsPerPage"
        @update:page="updatePage"
        @update:items-per-page="updateItemsPerPage"
        selectable-key="selectable"
        no-data-text="Aucun échantillon"
        dense
        show-select
        single-select
        disable-sort
    >
        <template v-slot:item.run.date="{ item }">
            <div v-if="type === 'simple'">
                {{ formatDate(item.run.date) }}
            </div>
            <div v-if="type === 'pair'">
                {{ formatDate(item.ngs_tumoral_library.run.date) }} <span class="font-weight-bold">(T)</span><br>
                {{ formatDate(item.ngs_non_tumoral_library.run.date) }} <span class="font-weight-bold">(NT)</span>
            </div>
        </template>
        <template v-slot:item.panel.name="{ item }">
            <div v-if="type === 'simple'">
                {{ item.panel.name }}
            </div>
            <div v-if="type === 'pair'">
                {{ item.ngs_tumoral_library.panel.name }} <span class="font-weight-bold">(T)</span><br>
                {{ item.ngs_non_tumoral_library.panel.name }} <span class="font-weight-bold">(NT)</span>
            </div>
        </template>
        <template v-slot:item.sample.laboratory_number="{ item }">
            <div v-if="type === 'simple'">
                {{ item.sample.laboratory_number }}
            </div>
            <div v-if="type === 'pair'">
                {{ item.ngs_tumoral_library.sample.laboratory_number }} <span class="font-weight-bold">(T)</span><br>
                {{ item.ngs_non_tumoral_library.sample.laboratory_number }} <span class="font-weight-bold">(NT)</span>
            </div>
        </template>
        <template v-slot:item.barcode.name="{ item }">
            <div v-if="type === 'simple'">
                {{ item.barcode.name }}
            </div>
            <div v-if="type === 'pair'">
                {{ item.ngs_tumoral_library.barcode.name }} <span class="font-weight-bold">(T)</span><br>
                {{ item.ngs_non_tumoral_library.barcode.name }} <span class="font-weight-bold">(NT)</span>
            </div>
        </template>
        <template v-slot:item.tumor="{ item }">
            <div v-if="type === 'simple'">
                {{ item.sample.tumor_type.shortname }}
            </div>
            <div v-if="type === 'pair'">
                {{ item.ngs_tumoral_library.sample.tumor_type.shortname }} <span class="font-weight-bold">(T)</span><br>
                {{ item.ngs_non_tumoral_library.sample.tumor_type.shortname }} <span class="font-weight-bold">(NT)</span>
            </div>
        </template>
        <template v-slot:item.data-table-select="{ item, isSelected, select }">
            <v-simple-checkbox v-if="item.selectable" v-model="isSelected"
                               @input="select(isSelected)"></v-simple-checkbox>
            <v-tooltip v-if="!item.selectable" bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" color="grey">
                        mdi-close
                    </v-icon>
                </template>
                <span>Contexte incompatible</span>
            </v-tooltip>
        </template>
    </v-data-table>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, PropSync, VModel, Vue} from 'vue-property-decorator'
import {DataTableHeader} from 'vuetify'
import {NgsAnalysis, NgsPairAnalysis} from "@/models";

@Component
export default class NgsSelectableAnalysesTable extends Vue {
    @VModel() selectedLibraries!: Array<NgsAnalysis | NgsPairAnalysis>
    @PropSync('page') syncedPage!: number
    @PropSync('itemsPerPage') syncedItemsPerPage!: number
    @Prop() items!: Array<NgsAnalysis | NgsPairAnalysis>
    @Prop({default: false}) readonly loading!: boolean
    @Prop() readonly serverItemsLength!: number
    @Prop() readonly referenceAnalysis!: NgsAnalysis | NgsPairAnalysis
    @Prop() readonly type!: 'simple' | 'pair'

    footerProps: { [key: string]: Array<number> } = {
        'items-per-page-options': [10, 25, 50, 100]
    }

    headers: Array<DataTableHeader> = [
        {
            text: 'Date',
            value: 'run.date',
        },
        {
            text: 'Panel',
            value: 'panel.name',
            width: '20%',
        },
        {
            text: 'DIAMIC',
            value: 'sample.diamic',
        },
        {
            text: 'Laboratoire',
            value: 'sample.laboratory_number',
        },
        {
            text: 'Barcode',
            value: 'barcode.name',
        },
        {
            text: 'Diagnostic',
            value: 'tumor',
        }
    ]

    get filteredItems(): Array<NgsAnalysis | NgsPairAnalysis> {
        return this.items.map((item: NgsAnalysis | NgsPairAnalysis) => {
            if (this.type === 'simple') {
                (item as any).selectable = item.id !== this.referenceAnalysis.id && (item as any).panel.type === (this.referenceAnalysis as any).panel.type
            }
            if (this.type === 'pair') {
                (item as any).selectable = item.id !== this.referenceAnalysis.id && ((item as any).ngs_non_tumoral_library.panel.type === (this.referenceAnalysis as any).ngs_non_tumoral_library.panel.type || (item as any).ngs_tumoral_library.panel.type === (this.referenceAnalysis as any).ngs_tumoral_library.panel.type)
            }
            return item
        })
    }

    updatePage(e: Event): void {
        this.$emit('pagination', {
            page: e,
            itemsPerPage: this.syncedItemsPerPage
        })
    }

    updateItemsPerPage(e: Event): void {
        this.$emit('pagination', {
            page: 1,
            itemsPerPage: e
        })
    }
}
</script>

<style scoped lang="css">
:deep(v-simple-checkbox--disabled) {
    display: none !important;
}
</style>
