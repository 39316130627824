<template>
    <div>
        <v-row
            v-if="filtersOpen"
            class="grey lighten-3 ma-3"
            align-content="center"
        >
            <v-col cols="12">
                <v-row
                    justify="space-around"
                    align-content="space-around"
                >
                    <v-col cols="12" lg="4">
                        <date-picker
                            v-model="filters.date"
                            label="Date du run"
                            dense
                            outlined
                            hide-details
                        ></date-picker>
                    </v-col>
                    <v-col cols="12" lg="4">
                        <v-text-field
                            color="primary"
                            v-model="filters.diamic"
                            placeholder="N° DIAMIC"
                            dense
                            outlined
                            clearable
                            hide-details
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4">
                        <v-text-field
                            color="primary"
                            v-model="filters.laboratory_number"
                            placeholder="N° de laboratoire"
                            dense
                            outlined
                            clearable
                            hide-details
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <ngs-selectable-analyses-table
                    v-model="selectedAnalyses"
                    :reference-analysis="referenceAnalysis"
                    :loading="loading"
                    :items="analyses.data"
                    :server-items-length="analyses.total"
                    :page="analyses.current_page"
                    :items-per-page="analyses.per_page"
                    @pagination="retrievePagination"
                    :type="type"
                ></ngs-selectable-analyses-table>
            </v-col>
        </v-row>
        <btn
            @click="displayFilters()"
            v-if="!filtersOpen"
            :block="true"
            color="primary"
            icon="mdi-magnify"
            class="mt-3"
        >
            Chercher d'autres analyses
        </btn>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, VModel, Vue, Watch} from 'vue-property-decorator'
import Btn from '@/Components/generic/Btn.vue'
import DatePicker from '@/Components/generic/DatePicker.vue'
import mapValues from 'lodash/mapValues'
import route from "ziggy-js";
import throttle from "lodash/throttle";
import NgsSelectableAnalysesTable from "@/Components/domain-specific/ngs/tables/NgsSelectableAnalysesTable.vue";
import {NgsAnalysis, NgsPairAnalysis, NgsRun} from "@/models";
import {Pagination} from "@/laravel";

@Component({
    components: {
        NgsSelectableAnalysesTable,
        DatePicker,
        Btn
    }
})
export default class NgsAnalysesFilterAndTable extends Vue {
    @VModel() selectedAnalyses!: Array<NgsAnalysis|NgsPairAnalysis>
    @Prop() readonly referenceAnalysis!: NgsAnalysis|NgsPairAnalysis
    @Prop() readonly ngsRun!: NgsRun
    @Prop() readonly title!: string
    @Prop() readonly type!: 'simple' | 'pair'
    @Prop({ default: false }) readonly isDialogOpen?: boolean

    @Watch('isDialogOpen', {
        immediate: true
    })
    onDialogOpening(val: boolean) {
        if (val) {
            this.load()
        }

        if (!val) {
            this.filtersOpen = false
            this.analyses = {
                current_page: 1,
                data: [],
                per_page: 10,
                total: 0
            }
        }
    }

    @Watch('filters', {
        deep: true
    })
    onFiltersChange() {
        this.load()
    }

    filtersOpen: boolean = false
    filters: {
        date: string | null
        diamic: string | null
        laboratory_number: string | null
        page: string | null
        'items-per-page': string | null
    } = {
        date: null,
        diamic: null,
        laboratory_number: null,
        page: null,
        'items-per-page': null,
    }
    loading: boolean = false
    analyses: Partial<Pagination<NgsAnalysis|NgsPairAnalysis>> = {
        current_page: 1,
        data: [],
        per_page: 10,
        total: 0
    }

    displayFilters () {
        this.selectedAnalyses = []
        this.filtersOpen = true
    }

    reset (): void {
        this.filters = mapValues(this.filters, () => null)
    }

    load() {
        this.loading = true
        let url = `ngs_runs.analyses.${this.type}`

        if (this.filtersOpen) {
            if (this.type === 'simple') {
                url = 'ngs_analyses.simple'
            } else if (this.type === 'pair') {
                url = 'ngs_pair_analyses.pair'
            }
        }

        this.$axios.get(route(url, { ngs_run: this.ngsRun.id}), {
            params: this.filters
        })
            .then(({ data }) => {
                this.analyses = data
            })
            .finally(() => {
                this.loading = false
            })
    }

    retrievePagination ({ page, itemsPerPage }:{ page: number, itemsPerPage: number }): void {
        this.filters.page = page.toString()
        this.filters['items-per-page'] = itemsPerPage.toString()
    }

    created () {
        this.load = throttle(this.load, 1000)
    }
}
</script>

<style scoped lang="scss">
</style>
