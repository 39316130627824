<template>
    <div>
        <div class="pa-5">
            <div class="mb-5 font-weight-bold">Lectures et rapports</div>
            <element-row label="Lectures">
                <div v-if="cnvAnalysis.review">
                    <p>1ère lecture par: <span
                        class="font-weight-bold my-0">{{ cnvAnalysis.review.reviewer.name }}</span>
                        <span class="grey--text">
                            ({{ formatDate(cnvAnalysis.review.created_at) }})
                        </span>
                    </p>
                    <p v-if="$get(cnvAnalysis, 'review.review_comment', false)" class="grey--text">
                        Commentaire: {{ cnvAnalysis.review.review_comment }}
                    </p>
                    <cnv-review-request-list
                        v-if="cnvAnalysis.review.reviewer !== null && cnvAnalysis.review.validator === null && cnvAnalysis.review.review_requests.length > 0"
                        :review-requests="cnvAnalysis.review.review_requests"
                    ></cnv-review-request-list>
                    <p v-if="cnvAnalysis.review.hasOwnProperty('validator_id') && cnvAnalysis.review.validator_id !== null">
                        2ème lecture par: <span
                        class="font-weight-bold my-0">{{ cnvAnalysis.review.validator.name }}</span>
                        <span class="grey--text">
                            ({{ formatDate(cnvAnalysis.review.updated_at) }})
                        </span>
                    </p>
                    <p v-if="$get(cnvAnalysis, 'review.validation_comment', false)"
                       class="grey--text">
                        Commentaire: {{ cnvAnalysis.review.validation_comment }}
                    </p>
                </div>
            </element-row>
        </div>
        <div class="pa-5">
            <report
                label="Rapports (français)"
                :reports="cnvAnalysis.french_reports"
                :analysis="cnvAnalysis"
            ></report>
        </div>
        <div class="pa-5">
            <report
                label="Rapports (allemand)"
                :reports="cnvAnalysis.german_reports"
                :analysis="cnvAnalysis"
            ></report>
        </div>
    </div>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Component, Prop, Vue} from 'vue-property-decorator'
import ElementRow from '@/Components/generic/ElementRow.vue'
import CnvReviewRequestList from '@/Components/domain-specific/cnv/helpers/CnvReviewRequestList.vue'
import {CnvAnalysis} from "@/models";
import Report from "@/Components/generic/Report.vue";

@Component({
    components: {
        Report,
        ElementRow,
        CnvReviewRequestList
    }
})
export default class CnvReportsSection extends Vue {
    @Prop() readonly cnvAnalysis!: CnvAnalysis
}
</script>

<style lang="scss" scoped>

</style>
